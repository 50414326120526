import { gql as apolloGql, TypedDocumentNode } from '@apollo/client';
import { SubscriptionPlanTypeEnum } from '../../modules/dashboard/graphql/dashboard.type';
import { UserClient } from '../../types/common.type';
import { InputMaybe, Scalars } from '../../__generated__/graphql';

export const FETCH_USER_QUERY = apolloGql`query CurrentUser {
   currentUser {
    id
    firstName
    lastName
    profileImage
    activeSubscriptions {
      expireDate
      createdAt
      features
      subscriptionPlan {
        id
        slug
        order
        featureConfig
      }
    }
    userClient {
      directTaxCount
      directTaxUsedCount
    }
  }

}` as TypedDocumentNode<{
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'UserData';
    id?: string | null;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    profileImage?: string | null;
    activeSubscriptions?: Array<{
      __typename?: 'SubscriptionPlanPurchase';
      expireDate?: string | null;
      createdAt?: string | null;
      features?: Array<string | null> | null;
      subscriptionPlan?: {
        __typename?: 'SubscriptionPlan';
        id?: string | null;
        slug?: string | null;
        order?: number | null;
        featureConfig?: InputMaybe<Scalars['JSON']['input']>;
      } | null;
    } | null> | null;
    userClient?: UserClient;
  } | null;
}>;

export const SUBSCRIPTION_PLANS_QUERY = apolloGql`
  query SubscriptionPlans {
    subscriptionPlans {
      count
      data {
        id
        features
        title
        price
        order
        featureConfig
        featureList
        description
        type
        slug
      }
    }
  }
` as TypedDocumentNode<{
  __typename?: 'Query';
  subscriptionPlans?: {
    __typename?: 'SubscriptionPlansResponse';
    data?: Array<{
      __typename?: 'SubscriptionPlan';
      id?: string | null;
      title?: string | null;
      order?: number | null;
      price?: number | null;
      features?: Array<string | null> | null;
      featureConfig?: InputMaybe<Scalars['JSON']['input']>;
      featureList?: Array<string | null> | null;
      description?: string | null;
      slug?: string | null;
      type?: SubscriptionPlanTypeEnum | null;
    } | null> | null;
  } | null;
}>;
