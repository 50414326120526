import { useQuery } from '@apollo/client';
import {
  Button,
  Card,
  DatePicker,
  Form,
  Select,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { capitalize, omit } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  IncomeTaxForm,
  IncomeTaxFormsFilter,
  InputMaybe,
  SortOrder,
} from '../../__generated__/graphql';
import { Export, EyeIcon, SelectDropdownIcon } from '../../assets/svg';
import axiosInstance from '../../common/axiosInstance';
import {
  defaultDateFormat,
  EMPTY_STATE,
  FILED_STATUS,
  ITR_FILL_START_YEAR,
  LIMIT,
  ROUTES,
} from '../../common/constants';
import { downloadCsv, generateYearOptions } from '../../common/utils';
import CommonPagination from '../../components/CommonPagination';
import CommonSearch from '../../components/CommonSearch';
import TableComponent from '../../components/CommonTable';
import StatusTag from '../../components/CommonTag';
import InfiniteSelect from '../../components/InfiniteSelect';
import MyBreadcrumb from '../../components/MyBreadcrumb';
import useRouter from '../../hooks/useRouter';
import { ASSESSEES_LIST, FILING_TYPES, ITF_LIST } from './graphql/Queries';
const { Text } = Typography;
const { RangePicker } = DatePicker;
dayjs.extend(utc);

const initialFilters = {
  search: '',
  skip: 0,
  limit: LIMIT,
};

const initialValue = {
  filters: {
    assesseeId: null,
    assessmentYear: null,
    assessee: null,
    formType: null,
    financialYear: null,
    status: null,
    filingType: null,
    duration: {
      end: null,
      start: null,
    },
  },
};

const OtherFiled = () => {
  const [filterForm] = Form.useForm();
  const { navigate } = useRouter();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [listFilter, setListFilter] = useState(initialFilters);
  const filters = Form.useWatch('filters', filterForm) ?? initialValue.filters;
  const [searchParams] = useSearchParams();
  const searchValue =
    searchParams.get('client') || searchParams.get('clientLabel');
  const client = searchParams.get('clientLabel');

  const filter: InputMaybe<IncomeTaxFormsFilter> = useMemo(
    () => ({
      assesseeId: filters?.assessee?.value ?? '',
      skip: (currentPage - 1) * LIMIT,
      search: listFilter.search,
      limit: listFilter.limit,
      filingType: filters?.filingType,
      assessmentYear: filters?.assessmentYear,
      financialYear: filters?.financialYear,
      status: filters?.status,
      duration: {
        end: filters.duration?.[1] || '',
        start: filters.duration?.[0] || '',
      },
    }),
    [filters, listFilter, currentPage],
  );

  const { data: { itfList } = {}, loading } = useQuery(ITF_LIST, {
    fetchPolicy: 'network-only',
    onError() {},
    variables: { filter },
  });

  const { data: { itfFilingTypes } = {}, loading: loadFilingTypes } = useQuery(
    FILING_TYPES,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  useEffect(() => {
    if (searchValue) {
      filterForm.setFieldsValue({
        filters: {
          assessee:
            {
              value: searchParams.get('client'),
              label: searchParams.get('clientLabel'),
            } ?? null,
        },
      });
    }
  }, []);

  const filingTypes = itfFilingTypes?.map((type) => ({
    label: type,
    value: type,
  }));

  const handlePagination = (current: number) => {
    setCurrentPage(current);
    setListFilter((prev) => ({ ...prev, skip: (current - 1) * LIMIT }));
  };

  const handleClear = () => {
    filterForm?.resetFields();
  };

  const exportCsv = async () => {
    const response = await axiosInstance.post('/v1/itf/export-csv', {
      filter: {
        ...omit(filter, ['limit', 'skip']),
      },
    });
    downloadCsv(response);
  };

  const columns: ColumnType<IncomeTaxForm>[] = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      fixed: 'left',
      render: (_val, _, i) => i + 1 + LIMIT * (currentPage - 1),
    },
    {
      title: 'PAN',
      dataIndex: ['assessee', 'username'],
      key: 'pan',
      render: (text) => text ?? '-',
    },
    {
      title: 'Type',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      render: (formType) =>
        formType ? (
          <Tooltip placement="topLeft" title={formType}>
            {formType?.slice(0, 15)}...
          </Tooltip>
        ) : (
          '-'
        ),
    },
    {
      title: 'A.Y.',
      dataIndex: 'assessmentYear',
      key: 'assessmentYear',
      fixed: 'left',
      render: (text) => text ?? '-',
    },
    {
      title: 'Form Number',
      dataIndex: 'formNo',
      key: 'formNo',
      render: (text) => text ?? '-',
    },
    {
      title: 'Number Of Filings',
      dataIndex: 'filingCount',
      key: 'filingCount',
      render: (text) => text ?? '-',
    },
    {
      title: 'Submitted Date',
      dataIndex: 'formSubmittedDate',
      key: 'formSubmittedDate',
      render: (formSubmittedDate) =>
        formSubmittedDate
          ? dayjs.utc(formSubmittedDate)?.format(defaultDateFormat)
          : '-',
    },
    {
      title: 'Filing Type',
      dataIndex: 'filingType',
      key: 'filingType',
      render: (text) => text ?? '-',
    },
    {
      title: 'Filing Date',
      dataIndex: 'filingDate',
      key: 'filingDate',
      render: (filingDate) =>
        filingDate ? dayjs.utc(filingDate)?.format(defaultDateFormat) : '-',
    },
    {
      title: 'Filed By',
      dataIndex: 'filedBy',
      key: 'filedBy',
      render: (text: string) => {
        return text ? (text === 'SELF' ? capitalize(text) : text) : '-';
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <StatusTag status={status} />,
    },
    {
      title: 'Action',
      fixed: 'right',
      render: (record) => (
        <Space>
          <span
            onClick={() =>
              navigate(`${record?._id}/${ROUTES?.DETAIL}?response=true`)
            }
          >
            <EyeIcon key="view" />
          </span>
        </Space>
      ),
    },
  ];

  return (
    <div className="container">
      <div className="mt-16 mb-16">
        <MyBreadcrumb
          username={client ?? (itfList?.data?.[0]?.assessee?.username || '')}
          id={
            itfList?.data?.[0]?.assessee?._id ??
            (searchParams.get('client') || '')
          }
          assesseeId={
            itfList?.data?.[0]?.assessee?._id ??
            (searchParams.get('client') || '')
          }
        />
      </div>
      <div className="d-flex justify-between align-center">
        <Text className="heading">Other Filed Forms</Text>
        <CommonSearch
          handleChangeSearch={(val) =>
            setListFilter((prev) => ({ ...prev, search: val }))
          }
          iconPlacement="right"
          allowClear
        />
      </div>
      <div className="d-flex mt-16">
        <Button
          type="primary"
          className="mr-8 d-flex align-center"
          icon={<Export />}
          onClick={() => exportCsv()}
        >
          Export To CSV
        </Button>
      </div>
      <Card className="mt-16">
        <Form
          form={filterForm}
          layout="vertical"
          onValuesChange={() => setCurrentPage(1)}
          initialValues={initialFilters}
          className="other-filed-form"
        >
          <div className="filters d-flex align-center gap-8">
            <Form.Item
              name={['filters', 'assessee']}
              label="Select Client"
              className="select"
            >
              <InfiniteSelect
                query={ASSESSEES_LIST}
                variableSelector={({ skip, limit, search }) => ({
                  filter: {
                    skip,
                    limit,
                    search,
                    withName: false,
                  },
                  sort: {
                    sortBy: SortOrder.Desc,
                  },
                })}
                dataSelector={({ assessees }) => {
                  return (
                    assessees?.data?.map((item) => ({
                      label: [item?.username, item?.name].join(' | ') ?? '',
                      value: item?._id ?? '',
                    })) ?? []
                  );
                }}
                countSelector={({ assessees }) => assessees?.count ?? 0}
                allowSearch
                placeholder="PAN, username"
                allowClear
                fetchPolicy="network-only"
              />
            </Form.Item>
            <Form.Item
              name={['filters', 'assessmentYear']}
              label="Assessment year"
              className="select"
            >
              <Select
                suffixIcon={<SelectDropdownIcon />}
                placeholder="All"
                options={generateYearOptions(ITR_FILL_START_YEAR)}
                allowClear
                showSearch
              />
            </Form.Item>
            <Form.Item
              name={['filters', 'filingType']}
              label="Filing type"
              className="select"
            >
              <Select
                placeholder="All"
                loading={loadFilingTypes}
                options={filingTypes}
                allowClear
                suffixIcon={<SelectDropdownIcon />}
              />
            </Form.Item>
            <Form.Item
              name={['filters', 'status']}
              label="Status"
              className="select"
            >
              <Select
                placeholder="All"
                options={FILED_STATUS}
                allowClear
                suffixIcon={<SelectDropdownIcon />}
              />
            </Form.Item>
            <Form.Item
              name={['filters', 'financialYear']}
              label="Financial Year"
              className="select"
            >
              <Select
                placeholder="All"
                options={generateYearOptions(ITR_FILL_START_YEAR)}
                allowClear
                showSearch
                suffixIcon={<SelectDropdownIcon />}
              />
            </Form.Item>
            <Form.Item
              name={['filters', 'duration']}
              label="Period"
              className="select"
            >
              <RangePicker
                className="full-width"
                placeholder={['From', 'To']}
                format={defaultDateFormat}
              />
            </Form.Item>
            <Form.Item className="d-flex align-end align-self-end">
              <Button
                type="link"
                onClick={handleClear}
                disabled={
                  !(
                    filters.status ||
                    filters.assessee ||
                    filters.assessmentYear ||
                    filters.filingType ||
                    filters.financialYear ||
                    filters.duration
                  )
                }
              >
                Clear All
              </Button>
            </Form.Item>
          </div>
        </Form>
        <TableComponent<IncomeTaxForm>
          columns={columns}
          rowKey={(record) => record?._id as string}
          dataSource={itfList?.data as IncomeTaxForm[]}
          pagination={false}
          loading={loading}
          scroll={{ x: 'max-content' }}
          locale={EMPTY_STATE}
        />
        {itfList?.count ? (
          <CommonPagination
            count={itfList?.count}
            handlePagination={handlePagination}
            currentPage={currentPage}
          />
        ) : null}
      </Card>
    </div>
  );
};

export default OtherFiled;
