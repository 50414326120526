import { Row, Col, Card, Typography, Badge } from 'antd';
import {
  GUTTER_VARIATIONS,
  NA_PLACEHOLDER_TEXT,
} from '../../../common/constants';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ProfileCardProps } from '../graphql/clients.type';

dayjs.extend(relativeTime);
const { Text } = Typography;

const ProfileCard = ({ title, data }: ProfileCardProps) => {
  return (
    <Card className="mb-24 pl-8 pr-8">
      <Text className="heading">{title}</Text>
      <Row gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]} className="mt-16">
        <Col xs={8} xl={8} className="d-grid">
          <Text className="title">Name of Assessee</Text>
          <Text className="value">{data?.name || NA_PLACEHOLDER_TEXT}</Text>
        </Col>
        <Col xs={8} xl={8} className="d-grid">
          <Text className="title">PAN</Text>
          <Text className="value">{data?.username || NA_PLACEHOLDER_TEXT}</Text>
        </Col>
        <Col xs={8} xl={8} className="d-grid">
          <Text className="title">Activity Logs</Text>
          <Text className="value text-size-12">
            <Badge
              status="default"
              text={
                data?.activityLogs?.updatedAt
                  ? dayjs(data?.activityLogs?.updatedAt).fromNow()
                  : NA_PLACEHOLDER_TEXT
              }
            />
          </Text>
        </Col>
      </Row>
      <Row gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]} className="mt-16">
        <Col xs={8} lg={8} xl={8} className="d-grid">
          <Text className="title">Username</Text>
          <Text className="value">{data?.username || NA_PLACEHOLDER_TEXT}</Text>
        </Col>
        <Col xs={8} lg={8} xl={8} className="d-grid">
          <Text className="title">Phone Number</Text>
          <Text className="value">
            {data?.phoneNumber || NA_PLACEHOLDER_TEXT}
          </Text>
        </Col>
        <Col xs={8} lg={8} xl={8} className="d-grid">
          <Text className="title">Date of Incorporation</Text>
          <Text className="value">
            {data?.incorporateDate
              ? data?.incorporateDate
              : NA_PLACEHOLDER_TEXT}
          </Text>
        </Col>
      </Row>
      <Row gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]} className="mt-16">
        <Col xs={24} lg={24} xl={24} className="d-grid">
          <Text className="title">Address</Text>
          <Text className="value">{data?.address || NA_PLACEHOLDER_TEXT}</Text>
        </Col>
      </Row>
    </Card>
  );
};

export default ProfileCard;
