import { Outlet } from 'react-router-dom';
import { Cookie } from './common/utils';

const PrivateRoute = () => {
  const idToken = Cookie.get(Cookie.keys.TOKEN);
  const redirectProfile = `${process.env.REACT_APP_REDIRECT_PROFILE_URL}`;
  if (!idToken) {
    window.open(redirectProfile, '_self');
  }
  return <Outlet />;
};
export default PrivateRoute;
