import { gql } from '../../../__generated__';

export const ASSESSEE_STATES =
  gql(`query AssesseeStats($where: AssesseeUniqueInput) {
  assesseeStats(where: $where) {
    allNotices
    dueToday
    last24Hours
    openNotices
    oneWeekDue
    overDue
    loginFailed
    totalPan
  }
}`);
export const E_PROCEEDINGS =
  gql(`query E_Proceedings($filter: EProceedingsFilter) {
  eProceedings(filter: $filter) {
    count
    data {
      _id
      assessee {
        _id
        username
        name
      }
      noticeReferenceId
      assessmentYear
      financialYear
      responseDueDate
      letterPdf {
        attachments {
          name
          url
        }
      }
      status
      remarks
    }
  }
}`);

export const CALENDAR_COUNT =
  gql(`query EProceedingsCalenderCount($filter: EProceedingsCalenderCountFilter!) {
    eProceedingsCalenderCount(filter: $filter) {
      data
      count
      totalIssuedOn
      totalResponseDueCount
      totalCombined
    }
  }`);

export const CURRENT_SYNC_CALENDAR = gql(`query CurrentUserCalender {
    currentUserCalender {
      type
    }
  }`);
