import { CloseCircleOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import { Result, Typography } from 'antd';
import { BrowserRouter as Router, Navigate, useRoutes } from 'react-router-dom';
import App from './app/App';
import {
  ERROR_PAGE_SUBTITLE,
  ERROR_PAGE_TITLE,
  ROUTES,
} from './common/constants';
import LoaderComponent from './components/LoaderComponent';
import MaintenancePage from './components/MaintenancePage';
import Error404 from './Error404';
import Clients from './modules/clients/Clients';
import PanDashboard from './modules/clients/PanDashboard';
import SyncLog from './modules/clients/SyncLog';
import Dashboard from './modules/dashboard/Dashboard';
import FiledReturn from './modules/filed-return/FiledReturn';
import FiledReturnStatus from './modules/filed-return/FilesReturnStatus';
import OtherFiled from './modules/other-filed/OtherFiled';
import OtherFiledResponse from './modules/other-filed/OtherFiledResponse';
import EProcessingResponse from './modules/tax-litigation/EProcessingResponse';
import TaxLitigation from './modules/tax-litigation/TaxLitigation';
import PrivateRoute from './PrivateRoute';

type FallbackProps = {
  error: Error | null;
  componentStack: string | null;
};

const { Paragraph } = Typography;

const MyFallbackComponent = ({ error, componentStack }: FallbackProps) => (
  <Result
    status="error"
    title={ERROR_PAGE_TITLE}
    subTitle={ERROR_PAGE_SUBTITLE}
  >
    <div className="desc">
      <Paragraph>
        <Typography.Title level={4}> Error:</Typography.Title>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
        {error?.message?.toString()}
      </Paragraph>
      <Paragraph>
        <Typography.Title level={4}> Stacktrace:</Typography.Title>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
        {componentStack}
      </Paragraph>
    </div>
  </Result>
);

const RoutesCollection = () => {
  const DASHBOARD = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.MAIN,
              element: <Navigate to={ROUTES?.DIRECT_TAX} replace />,
            },
          ],
        },
      ],
    },
  ];

  const DIRECT_TAX_DASHBOARD = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.DIRECT_TAX,
              element: <Dashboard />,
            },
          ],
        },
      ],
    },
  ];

  const DIRECT_TAX_DASHBOARD_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.DIRECT_TAX,
              children: [
                {
                  path: ROUTES?.CLIENT,
                  element: <Clients />,
                },
                {
                  path: ROUTES?.FILED_RETURN,
                  element: <FiledReturn />,
                },
                {
                  path: ROUTES?.OTHER_FILED,
                  element: <OtherFiled />,
                },
                {
                  path: ROUTES?.TAX_LITIGATION,
                  element: <TaxLitigation />,
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const CLIENT_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.DIRECT_TAX,
              children: [
                {
                  path: ROUTES?.CLIENT,
                  children: [
                    {
                      path: ROUTES?.SYNC_LOG,
                      element: <SyncLog />,
                    },
                    {
                      path: ROUTES?.PAN_DASHBOARD,
                      element: <PanDashboard />,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const STATS_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.DIRECT_TAX,
              children: [
                {
                  path: ROUTES?.CLIENT,
                  children: [
                    {
                      path: ROUTES?.PAN_DASHBOARD,
                      children: [
                        {
                          path: ROUTES?.TAX_LITIGATION,
                          element: <TaxLitigation />,
                        },
                        {
                          path: ROUTES?.FILED_RETURN,
                          element: <FiledReturn />,
                        },
                        {
                          path: ROUTES?.OTHER_FILED,
                          element: <OtherFiled />,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];
  const STATS_MODULES_RESPONSE = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.DIRECT_TAX,
              children: [
                {
                  path: ROUTES?.CLIENT,
                  children: [
                    {
                      path: ROUTES?.PAN_DASHBOARD,
                      children: [
                        {
                          path: ROUTES?.TAX_LITIGATION,
                          children: [
                            {
                              path: ROUTES?.E_PROCESSING_RESPONSE,
                              element: <EProcessingResponse />,
                            },
                          ],
                        },
                        {
                          path: ROUTES?.FILED_RETURN,
                          children: [
                            {
                              path: ROUTES?.FILED_RETURN_STATUS,
                              element: <FiledReturnStatus />,
                            },
                          ],
                        },
                        {
                          path: ROUTES?.OTHER_FILED,
                          children: [
                            {
                              path: ROUTES?.OTHER_FILED_DETAILS,
                              element: <OtherFiledResponse />,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const FILED_RETURN_FORM_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.DIRECT_TAX,
              children: [
                {
                  path: ROUTES?.FILED_RETURN,
                  children: [
                    {
                      path: ROUTES?.FILED_RETURN_STATUS,
                      element: <FiledReturnStatus />,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const OTHER_FILED_FORM_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.DIRECT_TAX,
              children: [
                {
                  path: ROUTES?.OTHER_FILED,
                  children: [
                    {
                      path: ROUTES?.OTHER_FILED_DETAILS,
                      element: <OtherFiledResponse />,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const E_PROCESSING_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.DIRECT_TAX,
              children: [
                {
                  path: ROUTES?.TAX_LITIGATION,
                  children: [
                    {
                      path: ROUTES?.E_PROCESSING_RESPONSE,
                      element: <EProcessingResponse />,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const OTHER_MODULES = [
    {
      path: ROUTES.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES.MAIN,
          element: <App />,
          children: [
            {
              path: '*',
              element: <Error404 />,
            },
          ],
        },
      ],
    },
  ];

  const element = useRoutes([
    ...DASHBOARD,
    ...DIRECT_TAX_DASHBOARD,
    ...DIRECT_TAX_DASHBOARD_MODULES,
    ...CLIENT_MODULES,
    ...STATS_MODULES,
    ...STATS_MODULES_RESPONSE,
    ...FILED_RETURN_FORM_MODULES,
    ...OTHER_FILED_FORM_MODULES,
    ...E_PROCESSING_MODULES,
    ...OTHER_MODULES,
  ]);
  return element;
};

const RoutesWrapper = () => {
  const loading = false;

  // use this variable from envs so that we can able to run maintenance page on runtime.
  const maintenance = process.env.REACT_APP_MAINTENANCE_ENABLE;

  if (loading) return <LoaderComponent />;
  return (
    <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
      <Router>
        {maintenance === 'true' ? <MaintenancePage /> : <RoutesCollection />}
      </Router>
    </Sentry.ErrorBoundary>
  );
};
export default RoutesWrapper;
