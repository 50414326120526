import { DesktopRegular } from '../assets/svg';

function MobileRestriction() {
  return (
    <div className="mobile-restriction-screen width-full bg-background2 d-flex align-center justify-center flex-vertical gap-8">
      <DesktopRegular />
      <p className="text-medium text-center mobile-restriction-text">
        Please use desktop to continue using Notice Board
      </p>
    </div>
  );
}

export default MobileRestriction;
