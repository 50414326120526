import { RcFile } from 'antd/es/upload';
import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import JSCookie from 'js-cookie';
import { isArray } from 'lodash';
import { NoUndefinedRangeValueType } from 'rc-picker/lib/PickerInput/RangePicker';
import { NavigateFunction } from 'react-router-dom';
import { messageContext } from '../components/AppContextHolder';
import { YearOption } from '../types/common.type';
import { REGEX } from './constants';

export const formValidatorRules = {
  required: {
    required: true,
    message: 'Required',
  },
  email: () => ({
    validator(_rule: unknown, value?: string) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX.EMAIL?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('The input is not valid E-mail!');
      }
      return Promise?.resolve();
    },
  }),
  name: () => ({
    validator(_rule: unknown, value?: string) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX.NAME?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Please enter valid Name');
      }
      return Promise?.resolve();
    },
  }),
  number: () => ({
    validator(_rule: unknown, value: string) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!Number(value) || !REGEX.NUMBER?.test(Number(value).toString())) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid Number');
      }
      return Promise?.resolve();
    },
  }),
};

export const getBase64 = (file: RcFile) =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader?.result);
    reader.onerror = (error) => reject(error);
  });

export const getTimeFromMins = (mins: number) => {
  const hours = Math.floor(mins / 60);
  const minutes = mins % 60;
  return `${hours}h ${minutes}m`;
};

export const getBase64File = (
  img: Blob,
  // eslint-disable-next-line no-unused-vars
  callback: (result: string | ArrayBuffer | null) => void,
) => {
  // eslint-disable-next-line no-undef
  const reader = new FileReader();
  reader?.addEventListener('load', () => callback(reader?.result));
  reader?.readAsDataURL(img);
};

export const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    messageContext.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file?.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    messageContext.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

export const handleProtectedNavigation = (
  allow: boolean,
  callback: NavigateFunction,
  path: number,
) => (allow ? callback(path) : false);

// Portal related methods
export const injectUsingPortal = (portalId: string) =>
  // eslint-disable-next-line no-undef
  document.getElementById(portalId);

export const isPortalIdExists = (portalId: string) =>
  !!injectUsingPortal(portalId);

// create cookie
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setCookie = (
  name: string,
  value: string | number | boolean,
  minutes: number,
) => {
  let expires = '';
  if (minutes) {
    const date = new Date();
    date?.setTime(date?.getTime() + minutes * 60 * 1000);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    expires = `; expires=${(date as any)?.toGMTString()}`; //as using toGMTString
  }
  // eslint-disable-next-line no-undef
  document.cookie = `${name}=${value}${expires}; path=/`;
};

// retrieve cookie
export const getCookie = (cookieName: string) => {
  const name = `${cookieName}=`;
  let returnCookie = '';
  // eslint-disable-next-line no-undef
  const decodedCookie = decodeURIComponent(document?.cookie);
  const cookieArray = decodedCookie?.split(';');
  if (cookieArray?.length > 0) {
    // eslint-disable-next-line array-callback-return
    cookieArray?.map((item) => {
      let cookie = item;
      while (cookie?.charAt(0) === ' ') {
        cookie = cookie?.substring(1);
      }
      if (cookie?.indexOf(name) === 0) {
        returnCookie = cookie?.substring(name?.length, cookie?.length);
      }
    });
    return returnCookie;
  }
};

// force expired cookie or delete
export const deleteCookie = (name: string) => {
  // eslint-disable-next-line no-undef
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export const formItemProps = { normalize: (value: string) => value?.trim() };

//
const cookieApi = JSCookie.withAttributes({
  domain: process.env.REACT_PUBLIC_COOKIE_DOMAIN,
  path: process.env.REACT_PUBLIC_COOKIE_PATH,
  expires: Number(process.env.REACT_PUBLIC_COOKIE_EXPIRES),
  secure: process.env.NODE_ENV === 'production',
}).withConverter({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  write: (value: any) => JSON.stringify(value),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  read: (value: any) => JSON.parse(value),
});

export class Cookie {
  static keys = {
    TOKEN: 'TOKEN',
    REFRESH_TOKEN: 'REFRESH_TOKEN',
    USER: 'USER',
  };

  static get = cookieApi.get;

  static set = cookieApi.set;

  static remove = cookieApi.remove;

  static clear() {
    Object.values(Cookie.keys).forEach((key) => Cookie.remove(key));
  }
}

export function generateYearOptions(startYear: number): YearOption[] {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: currentYear - startYear + 1 }, (_, index) => {
    const year = startYear + index;
    const nextYearShort = (year + 1).toString();
    return {
      label: `${year}-${nextYearShort}`,
      value: `${year}-${nextYearShort}`,
    };
  });
}

export function downloadCsv(response: AxiosResponse) {
  const blob = new Blob([response?.data], { type: 'text/csv' });
  const link = document.createElement('a');
  const url = window.URL.createObjectURL(blob);
  link.href = url;
  link.download = 'exported_data.csv';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}
type Presets = {
  label: string;
  value: [dayjs.Dayjs, dayjs.Dayjs];
};
export function getSelectedPresetesLabel(
  dates: NoUndefinedRangeValueType<dayjs.Dayjs>,
  range: Presets[],
) {
  if (dates) {
    const matchedPreset = range.find((preset) => {
      return (
        dayjs(dates[0]).isSame(
          isArray(preset.value) ? preset.value?.[0] : '',
        ) &&
        dayjs(dates[1]).isSame(isArray(preset.value) ? preset.value?.[1] : '')
      );
    });
    return matchedPreset;
  } else {
    return {};
  }
}
