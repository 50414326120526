import axios, { AxiosInstance } from 'axios';
import { Cookie } from './utils';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_REST_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = Cookie.get(Cookie.keys.TOKEN);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      // eslint-disable-next-line no-console
      console.error('Unauthorized, logging out...');
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
