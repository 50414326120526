import { Row, Col, Card, Typography } from 'antd';
import { NA_PLACEHOLDER_TEXT } from '../../../common/constants';
import { JurisdictionDetailsProps } from '../graphql/clients.type';
const { Text } = Typography;

const JurisdictionDetails = ({ details, title }: JurisdictionDetailsProps) => {
  return (
    <Card className="mb-24 pl-8 pr-8">
      <Text className="heading mb-16">{title}</Text>
      <Row gutter={[16, 16]} className="mt-16">
        <Col xs={8} xl={8} className="d-grid">
          <Text className="title">Area Code</Text>
          <Text className="value">
            {details?.areaCd || NA_PLACEHOLDER_TEXT}
          </Text>
        </Col>
        <Col xs={8} xl={8} className="d-grid">
          <Text className="title">AO Type</Text>
          <Text className="value">
            {details?.aoType || NA_PLACEHOLDER_TEXT}
          </Text>
        </Col>
        <Col xs={8} xl={8} className="d-grid">
          <Text className="title">AO Number</Text>
          <Text className="value">
            <span className="text-size-12">
              {details?.aoNo || NA_PLACEHOLDER_TEXT}
            </span>
          </Text>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="mt-16">
        <Col xs={8} xl={8} className="d-grid">
          <Text className="title">Range Code</Text>
          <Text className="value">
            {details?.rangeCd || NA_PLACEHOLDER_TEXT}
          </Text>
        </Col>
        <Col xs={8} xl={8} className="d-grid">
          <Text className="title">Jurisdiction</Text>
          <Text className="value">
            {details?.aoPplrName || NA_PLACEHOLDER_TEXT}
          </Text>
        </Col>
        <Col xs={8} xl={8} className="d-grid">
          <Text className="title">Email Id</Text>
          <Text className="value">
            {details?.aoEmailId || NA_PLACEHOLDER_TEXT}
          </Text>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="mt-16">
        <Col xs={24} lg={24} xl={24} className="d-grid">
          <Text className="title">Building Name</Text>
          <Text className="value">
            {details?.aoBldgDesc || NA_PLACEHOLDER_TEXT}
          </Text>
        </Col>
      </Row>
    </Card>
  );
};

export default JurisdictionDetails;
