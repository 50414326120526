import { Layout } from 'antd';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import withMobileScreenRestriction from '../common/hoc/withMobileScreenRestriction';
import Header from '../components/header/Header';
const { Content } = Layout;

const App = () => {
  useEffect(() => {
    // eslint-disable-next-line no-undef
    if ('serviceWorker' in navigator) {
      // eslint-disable-next-line no-undef
      navigator.serviceWorker.ready.then((registration) => {
        registration.update();
      });
    }
  }, [location?.pathname]);
  return (
    <Layout>
      <Header />
      <Content>
        <Outlet />
      </Content>
    </Layout>
  );
};

export default withMobileScreenRestriction(App);
