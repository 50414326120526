import { createContext, useContext, useReducer } from 'react';
import { Cookie } from './common/utils';
import { ChildrenType, User } from './types/appContext.type';

type InitialStateType = {
  isAuthenticated: boolean;
  userData: null | User;
  breadcrumbTitle: string | null;
};

type ActionType = {
  type:
    | 'SET_LOGGED_IN'
    | 'UPDATE_USER_PROFILE'
    | 'SET_LOGGED_OUT'
    | 'SET_BREADCRUMB_TITLE';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
};

type AppContextType = {
  state: InitialStateType;
  dispatch: React.Dispatch<ActionType>;
};

const initialState: InitialStateType = {
  isAuthenticated: false,
  userData: null,
  breadcrumbTitle: null,
};

const reducer = (state: InitialStateType, action: ActionType) => {
  switch (action?.type) {
    case 'SET_LOGGED_IN':
      return { ...state, isAuthenticated: true, userData: action?.data };
    case 'UPDATE_USER_PROFILE':
      return {
        ...state,
        isAuthenticated: true,
        userData: { ...state?.userData, ...action?.data },
      };
    case 'SET_LOGGED_OUT':
      Cookie.clear();
      return { ...state, isAuthenticated: false, userData: null };

    case 'SET_BREADCRUMB_TITLE':
      return {
        ...state,
        breadcrumbTitle: action.data,
      };

    default:
      return { ...state };
  }
};

const AppContext = createContext<AppContextType>({
  state: initialState,
  dispatch: () => {},
});

function AppContextProvider({ children }: ChildrenType) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value: AppContextType = {
    state,
    dispatch,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

function useAppContext(): AppContextType {
  return useContext(AppContext);
}

const AppContextConsumer = AppContext?.Consumer;

export { AppContext, AppContextConsumer, AppContextProvider, useAppContext };
