import { useEffect, useState } from 'react';
import MobileRestriction from '../../components/MobileRestriction';
import Header from '../../components/header/Header';

const withMobileScreenRestriction = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
) => {
  return function RestrictedComponent(props: P) {
    const [isMobile, setIsMobile] = useState<boolean>(
      typeof window !== 'undefined' &&
        window.matchMedia('(max-width: 768px)').matches,
    );

    useEffect(() => {
      const mediaQuery = window.matchMedia('(max-width: 768px)');

      const handleResize = (event: MediaQueryListEvent) => {
        setIsMobile(event.matches);
      };

      mediaQuery.addEventListener('change', handleResize);

      return () => {
        mediaQuery.removeEventListener('change', handleResize);
      };
    }, []);

    if (isMobile) {
      return (
        <>
          <Header />
          <MobileRestriction />
        </>
      );
    }

    return <WrappedComponent {...props} />;
  };
};

export default withMobileScreenRestriction;
