import {
  FileArrowDown,
  FileArrowDownFilled,
  FileFilled,
  FileOutlined,
  Gavel,
  GavelFilled,
  HomeFilled,
  HomeOutlined,
  UsersFilled,
  UsersOutlined,
} from '../../assets/svg';
import { MODULES, ROUTES } from '../../common/constants';

type MenuItemType = {
  label: string;
  key: string;
  icon: JSX.Element;
};

export const generateMenuItems = (pathname: string): MenuItemType[] => {
  const directTax = `/${ROUTES.DIRECT_TAX}`;
  const clientRoute = `/${ROUTES?.DIRECT_TAX}/${ROUTES?.CLIENT}`;
  const filedReturnRoute = `/${ROUTES?.DIRECT_TAX}/${ROUTES?.FILED_RETURN}`;
  const otherFiledRoute = `/${ROUTES?.DIRECT_TAX}/${ROUTES?.OTHER_FILED}`;
  const taxLitigationRoute = `/${ROUTES?.DIRECT_TAX}/${ROUTES?.TAX_LITIGATION}`;

  return [
    {
      label: MODULES?.DASHBOARD,
      key: directTax,
      icon:
        pathname === `/${ROUTES?.DIRECT_TAX}` ? (
          <HomeFilled />
        ) : (
          <HomeOutlined />
        ),
    },
    {
      label: MODULES?.CLIENT,
      key: clientRoute,
      icon:
        pathname === clientRoute || pathname.startsWith(clientRoute) ? (
          <UsersFilled />
        ) : (
          <UsersOutlined />
        ),
    },
    {
      label: MODULES?.FILED_RETURN,
      key: filedReturnRoute,
      icon:
        pathname === filedReturnRoute ||
        pathname.startsWith(filedReturnRoute) ? (
          <FileArrowDownFilled />
        ) : (
          <FileArrowDown />
        ),
    },
    {
      label: MODULES?.OTHER_FILED,
      key: otherFiledRoute,
      icon:
        pathname === otherFiledRoute || pathname.startsWith(otherFiledRoute) ? (
          <FileFilled />
        ) : (
          <FileOutlined />
        ),
    },
    {
      label: MODULES?.TAX_LITIGATION,
      key: taxLitigationRoute,
      icon:
        pathname === taxLitigationRoute ||
        pathname.startsWith(taxLitigationRoute) ? (
          <GavelFilled />
        ) : (
          <Gavel />
        ),
    },
  ];
};
