import { gql } from '../../../__generated__';

const ITR_FILED_RETURN_LIST =
  gql(`query ItrLists($sort: [ItrListSort], $filter: ItrListFilter) {
  itrList(sort: $sort, filter: $filter) {
    count
    data {
      _id
      assessmentYear
      acknowledgmentNo
      filingDate
      attachments {
        name
        url
      }
      assessee {
        _id
        username
        name
      }
      filedBy
      filingType
      forms {
        name
        url
      }
    }
  }
}
`);

const ASSESSEES_LIST =
  gql(`query SelectAssessees($filter: AssesseesFilter, $sort: [AssesseesSort]!) {
  assessees(filter: $filter, sort: $sort) {
    data {
      username
      _id
      name
    }
    count
  }
}`);

const ITR_STATUS_LIST = gql(`query Itr($where: ItrUniqueInput!) {
  itr(where: $where) {
    _id
    assessmentYear
    filingType
    acknowledgmentNo
    filedBy
    itrType
    filingDate
    attachments {
      name
      url
    }
    metadata
    createdAt
    updatedAt
    assessee {
      _id
      username
      name
    }
  }
}`);

export { ASSESSEES_LIST, ITR_FILED_RETURN_LIST, ITR_STATUS_LIST };
