import { gql } from '../../../__generated__';

const E_PROCEEDINGS_LIST =
  gql(`query EProceedingsList($filter: EProceedingsFilter, $sort: [EProceedingsSort]) {
  eProceedings(filter: $filter, sort: $sort) {
    data {
      _id
      assessee {
        _id
        username
        name
      }
      assessmentYear
      financialYear
      noticeReferenceId
      name
      issuedOn
      responseDueDate
      proceedingCloseDate
      proceedingCloseOrder
      proceedingLimitationDate
      letterPdf {
        from
        to
        attachments {
          name
          url
        }
      }
      noticeUs
      documentRefId
      description
      proceedingOpenDate
      type
      remarks
      status
      remarkByAdmin
      servedOn
      responses {
        _id
        remarks
        submittedOn
        filedBy
        type
        attachments {
          url
          name
        }
      }
      closureOrderAttachment {
        name
        url
      }
    }
    count
  }
}
`);

const E_PROCEEDINGS_DETAILS =
  gql(`query EProceeding($where: EProceedingUniqueInput!) {
    eProceeding(where: $where) {
      _id
      assessee {
        _id
        name
        username
      }
      name
      financialYear
      assessmentYear
      documentRefId
      noticeUs
      description
      letterPdf {
        _id
        from
        cc
        to
        attachments {
          name
          url
        }
      }
      status
      type
      servedOn
      responses {
        _id
        remarks
        submittedOn
        filedBy
        type
        attachments {
          url
          name
        }
      }
      closureOrderAttachment {
        name
        url
      }
    }
  }
`);

export { E_PROCEEDINGS_DETAILS, E_PROCEEDINGS_LIST };
