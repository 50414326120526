import type {
  LazyQueryHookOptions,
  OperationVariables,
  TypedDocumentNode,
} from '@apollo/client';
import { NetworkStatus } from '@apollo/client/core';
import { useLazyQuery } from '@apollo/client/react/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useRefValue from './useRefValue';

const LIMIT = 20;

export type QueryType<
  QueryData = unknown,
  QueryVariables extends OperationVariables = OperationVariables,
> = TypedDocumentNode<QueryData, QueryVariables>;

export type UseInfiniteQueryOptionsType<
  TData,
  TFilters = unknown,
  QueryData = unknown,
  QueryVariables extends OperationVariables = OperationVariables,
> = {
  limit?: number;
  skip?: boolean;
  initialData?: QueryData;
  dataSelector: (data: QueryData) => TData[];
  countSelector: (data: QueryData) => number;
  filters?: TFilters;
  withName?: boolean;
  variableSelector: (options: {
    skip: number;
    limit: number;
    filters: TFilters;
    withName?: boolean;
  }) => QueryVariables;
} & Pick<
  LazyQueryHookOptions,
  'client' | 'context' | 'fetchPolicy' | 'onCompleted' | 'onError'
>;

const useInfiniteQuery = <
  TData,
  TFilters = unknown,
  QueryData = unknown,
  QueryVariables extends OperationVariables = OperationVariables,
>(
  query: QueryType<QueryData, QueryVariables>,
  options: UseInfiniteQueryOptionsType<
    TData,
    TFilters,
    QueryData,
    QueryVariables
  >,
) => {
  const {
    limit = LIMIT,
    skip,
    initialData,
    filters,
    onError,
    onCompleted,
    ...rest
  } = options;
  const countSelector = useRefValue(options.countSelector);
  const dataSelector = useRefValue(options.dataSelector);
  const variableSelector$ = useRefValue(options.variableSelector);
  const hasInitialData = Boolean(initialData);

  const [data, setData] = useState<TData[]>(() =>
    initialData ? dataSelector.current(initialData) : [],
  );

  const [networkStatus, setNetworkStatus] = useState(() =>
    skip || hasInitialData ? NetworkStatus.ready : NetworkStatus.loading,
  );

  const [count, setCount] = useState<number>(() =>
    initialData ? countSelector.current(initialData) : 0,
  );

  const [fetchData] = useLazyQuery(query, {
    onCompleted(data) {
      setNetworkStatus(NetworkStatus.ready);
      onCompleted?.(data);
    },
    onError(error) {
      setNetworkStatus(NetworkStatus.ready);
      onError?.(error);
    },
    fetchPolicy: 'network-only',
    ...rest,
  });
  const loading = [NetworkStatus.loading, NetworkStatus.refetch].includes(
    networkStatus,
  );
  const offset = data.length;

  const loadingMore = networkStatus === NetworkStatus.fetchMore;

  const hasMore = data.length < count;

  const variableSelector = useCallback(
    (options: { skip: number; limit: number; filters?: TFilters }) => {
      const filters = options.filters as TFilters;
      return variableSelector$.current({ ...options, filters });
    },
    [variableSelector$],
  );

  useEffect(() => {
    if (skip || hasInitialData) return;

    (async () => {
      try {
        setNetworkStatus(NetworkStatus.loading);
        const { data } = await fetchData({
          variables: variableSelector({
            skip: 0,
            limit,
            filters,
          }),
        });
        if (data) {
          setData(dataSelector.current(data));
          setCount(countSelector.current(data));
        }
      } catch {
        //
      }
    })();
  }, [
    skip,
    fetchData,
    limit,
    hasInitialData,
    filters,
    variableSelector,
    dataSelector,
    countSelector,
  ]);

  type VariableSelector = typeof options.variableSelector;

  const refetch = useCallback(
    async (selector?: VariableSelector) => {
      try {
        const filters$ = filters as TFilters;
        const variables = (selector ?? variableSelector)({
          skip: 0,
          limit,
          filters: filters$,
        });
        setNetworkStatus(NetworkStatus.refetch);
        const { data } = await fetchData({ variables });
        if (data) {
          setData(dataSelector.current(data));
          setCount(countSelector.current(data));
        }
      } catch {
        //
      }
    },
    [fetchData, filters, limit, variableSelector, dataSelector, countSelector],
  );

  const fetchMore = useCallback(async () => {
    if (!hasMore) return;
    try {
      setNetworkStatus(NetworkStatus.fetchMore);
      const { data } = await fetchData({
        variables: variableSelector({ skip: offset, limit, filters }),
      });
      if (data) {
        setData((prev) => [...prev, ...dataSelector.current(data)]);
        setCount(countSelector.current(data));
      }
    } catch {
      //
    }
  }, [
    offset,
    filters,
    fetchData,
    limit,
    hasMore,
    variableSelector,
    dataSelector,
    countSelector,
  ]);

  const addItem = useCallback((item: TData) => {
    setData((prev) => [item, ...prev]);
    setCount((prev) => prev + 1);
  }, []);

  const updateItems = useCallback(
    (condition: (data: TData) => boolean, updatedData: Partial<TData>) => {
      setData((prev) =>
        prev.map((item) => {
          if (condition(item)) {
            return {
              ...item,
              ...updatedData,
            };
          }
          return item;
        }),
      );
    },
    [],
  );

  const removeItems = useCallback(
    (condition: (data: TData) => boolean) => {
      let totalItemsRemoved = 0;
      setData((prev) => {
        const filteredItems = prev.filter((item) => !condition(item));
        totalItemsRemoved = offset - filteredItems.length;
        return filteredItems;
      });

      setCount((prev) => prev - totalItemsRemoved);
    },
    [offset],
  );

  return useMemo(
    () => ({
      data,
      count,
      loading,
      loadingMore,
      hasMore,
      refetch,
      fetchMore,
      addItem,
      updateItems,
      removeItems,
    }),
    [
      data,
      count,
      loading,
      loadingMore,
      hasMore,
      refetch,
      fetchMore,
      addItem,
      updateItems,
      removeItems,
    ],
  );
};

export default useInfiniteQuery;
