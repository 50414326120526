import { useLayoutEffect, useRef } from 'react';

const useRefValue = <T>(value: T) => {
  const ref = useRef<T>(value);

  useLayoutEffect(() => {
    ref.current = value;
  }, [value]);

  return ref;
};

export default useRefValue;
