import Lottie from 'lottie-react';
import FailedTick from '../assets/gif/failed-tick.json';
import SuccessTick from '../assets/gif/success-tick.json';
import { VerificationStatusProps } from '../types/common.type';

function VerificationStatus({ title, isError }: VerificationStatusProps) {
  const animationData = isError ? FailedTick : SuccessTick;
  const statusClass = isError ? 'form-failed' : 'form-success';

  return (
    <div
      className={`${statusClass} d-flex flex-vertical gap-8 text-center align-center`}
    >
      <div className="tick-gif">
        <Lottie animationData={animationData} loop={true} />
      </div>
      <p className="page-info-desc body-l">{title}</p>
    </div>
  );
}

export default VerificationStatus;
