import { Breadcrumb } from 'antd';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../AppContext';
import { ROUTES } from '../common/constants';
import useRouter from '../hooks/useRouter';

function MyBreadcrumb({
  username = '',
  id,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  assesseeId,
}: {
  username?: string;
  id?: string;
  assesseeId?: string;
}) {
  const {
    state: { breadcrumbTitle },
  } = useContext(AppContext);
  const {
    location: { pathname },
  } = useRouter();
  const { params } = useRouter();
  const panId = params.id ? params.id : null;
  const nonClickableId = params.resId ? params.resId : null;
  const pathSnippets = pathname?.split('/')?.filter((i: string) => i !== '');

  const panRoute = id
    ? `/${ROUTES.DIRECT_TAX}/${ROUTES.CLIENT}/${ROUTES.PAN_DASHBOARD.replace(':id', id)}`
    : '';

  const breadcrumbItems = pathSnippets?.map((path, index) => {
    const url = `/${pathSnippets?.slice(0, index + 1)?.join('/')}`;
    const isLast = index === pathSnippets?.length - 1;
    let linkUrl = url;
    let formatPath = path.split('-').join(' ');

    if (path === panId) {
      linkUrl = panRoute;
      formatPath = username;
    }

    return {
      key: url,
      title:
        isLast && breadcrumbTitle ? (
          <span className="active-link text-primary">{breadcrumbTitle}</span>
        ) : (
          <Link
            to={linkUrl}
            className={`${isLast || path === nonClickableId ? 'active-link non-clickable' : 'ellipsis'}`}
          >
            {formatPath}
          </Link>
        ),
    };
  });

  return <Breadcrumb items={breadcrumbItems} className="my-breadcrumb" />;
}
export default MyBreadcrumb;
