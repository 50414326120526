import { Input } from 'antd';
import { debounce } from 'lodash';
import { SearchIcon } from '../assets/svg';

type CommonSearchPropType = {
  handleChangeSearch: (value: string) => void;
  iconPlacement: 'right' | 'left';
  placeholder?: string;
  className?: string;
  isFullWidth?: boolean;
  allowClear?: boolean;
};

const CommonSearch = ({
  handleChangeSearch,
  iconPlacement,
  placeholder = 'Search',
  className = '',
  isFullWidth = false,
  allowClear = false,
  ...rest
}: CommonSearchPropType) => {
  const debouncedHandleChangeSearch = debounce(handleChangeSearch, 500);

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedHandleChangeSearch(e.target.value);
  };

  return (
    <>
      <Input
        type="text"
        className={`common-search-input ${className} ${
          isFullWidth ? 'width-full' : ''
        }`}
        placeholder={placeholder}
        onChange={handleChangeValue}
        suffix={iconPlacement === 'right' && <SearchIcon />}
        prefix={iconPlacement === 'left' && <SearchIcon />}
        allowClear={allowClear}
        {...rest}
      />
    </>
  );
};
export default CommonSearch;
