import { gql } from '../../../__generated__';

export const CREATE_ASSESSEE = gql(`
  mutation CreateAssessee($data: AssesseeCreateInput!) {
  createAssessee(data: $data) {
    data {
      _id
      name
      phoneNumber
      loginStatus
      username
      password
      isActive
      createdBy
      incorporateDate
      address
      createdAt
      updatedAt
      lastSyncAt
      jurisdictionDetails
    }
    message
  }
}`);
export const UPDATE_ASSESSEE = gql(`
  mutation UpdateAssessee($where: AssesseeUniqueInput!, $data: AssesseeUpdateInput!) {
  updateAssessee(where: $where, data: $data) {
    data {
      _id
      name
      phoneNumber
      loginStatus
      username
      password
      isActive
      createdBy
      incorporateDate
      address
      createdAt
      updatedAt
      lastSyncAt
      jurisdictionDetails
      activityLogs {
        _id
        portal
        pan
        status
        message
        createdAt
        updatedAt
      }
    }
    message
  }
}`);

export const DELETE_ASSESSEE =
  gql(`mutation DeleteAssessee($where: AssesseeUniqueIdsInput!) {
  deleteAssessee(where: $where) {
    message
  }
}`);

export const SYNC_ASSESSEE =
  gql(`mutation SyncAssessee($where: AssesseeUniqueIdsInput!) {
  syncAssessee(where: $where) {
    message
  }
}`);
